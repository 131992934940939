/* GLOBAL */

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0 auto 6rem;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 1.5;
  background: #ccc;
}

/* HEADER */

header {
  width: 100%;
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: 2rem;
  padding: 1.5rem;
}

h1 {
  font-size: 1rem;
  margin: 0 0 2rem;
  color: black;
}

/* NEWSPAPER */

.newspaper {
  background: white;
  border: 6px solid black;
  box-shadow: 10px 10px 0 0 black;
  padding: 3rem;
  width: calc(100% - 2rem);
  margin: 5vh auto;
  max-width: 700px;
  animation: spin 1.5s ease-out .5s;
  transform: rotate(-3deg);
}

.newspaper .headline {
  font-size: 5rem;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  line-height: 1;
  margin: 0 0 2rem;
  text-align: center;
  text-transform: uppercase;
  line-height: .9;
}

.newspaper .paragraph {
  margin: 0 0 1rem;
  text-align: justify;
}

@keyframes spin {
  from {
    transform: scale(0.01) rotate(1500deg);
  }
  to {
    transform: scale(1) rotate(-3deg);
  }
}

/* MAIN */

main {
  width: 100%;
  max-width: 800px;
  padding: 2rem 10vw;
  margin: 0 auto;
}

/* FORMS */

button,
input {
  border-radius: 3px;
  outline: none;
  font-size: 1rem;
  font-family: sans-serif;
  border: none;
  cursor: pointer;
}
button {
  background: hsl(0, 0%, 20%);
  color: white;
  padding: .5rem 1.5rem;
}
button:hover {
  background: hsl(0, 0%, 15%);
}
input {
  padding: .5rem;
  background: hsl(0, 0%, 90%);
  min-width: 14rem;
  margin: 0 .5rem 0 0;
}
input:hover {
  background: hsl(0, 0%, 92%);
}
input:focus {
  background: hsl(0, 0%, 88%);
}

/* MISC */
